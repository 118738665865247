
          @import '@UI/scss/global.scss';
        
.main_active {
	flex: 1 0 auto;

	&:not(:first-child){
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid rgba(#000 ,0.1);
	}

	.main_active_inner {
		max-width: 1024px;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px;
		flex-direction: column;
	}
}

.main_active_title {
	line-height: 39px;
	letter-spacing: -0.3px;
	font-size: 26px;
	font-weight: bold;
	color: #151515;

	&:not(:first-child){
		margin-top: 40px;
	}
}

.main_active_history {
	&:not(:first-child){
		margin-top: 30px;
	}

	.main_history_list {
		&.type_basic {
			& > .main_list_item {
				&:not(:first-child){
					margin-top: 30px;
					padding-top: 30px;
					border-top: 1px solid rgba(0, 0, 0, 0.1);
				}
			}

			.main_head_title {
				&.type_emphasis,
				&.type_company {
					display: block;
					line-height: 24px;
					letter-spacing: -0.5px;
					font-size: 18px;
					font-weight: 600;
					color: #333;

					&:not(:first-child){
						margin-top: 5px;
					}
				}
			}

			.main_head_text {
				&.type_position {
					display: block;
					line-height: 22px;
					letter-spacing: -0.7px;
					font-size: 15px;
					color: #777;

					&:not(:first-child){
						margin-top: 5px;
					}
				}

				&.type_basic {
					display: block;
					line-height: 22px;
					letter-spacing: -0.7px;
					font-size: 15px;
					font-weight: 700;
					color: #333;
				}
			}

			.main_item_result {
				&:not(:first-child){
					margin-top: 20px;
				}

				.main_result_description {
					&:not(:first-child){
						margin-top: 15px;
					}

					.main_description_title {
						display: block;
						line-height: 22px;
						letter-spacing: -0.7px;
						font-size: 16px;
						font-weight: 700;
						color: #333;
					}

					.main_description_data {
						&:not(:first-child){
							margin-top: 10px;
						}

						.main_data_text {
							display: block;
							line-height: 22px;
							letter-spacing: -0.5px;
							word-break: keep-all;
							font-size: 15px;
							color: #333;

						}

						.main_data_text {
							&:not(:first-child){
								margin-top: 5px;
							}
						}

						.main_data_list {
							&.type_dot {
								.main_list_item {
									display: flex;
									flex-direction: row;

									&:before {
										content: "";
										width: 3px;
										height: 3px;
										display: inline-block;
										margin-top: 8px;
										margin-left: 6px;
										margin-right: 6px;
										vertical-align: top;
										flex: 0 0 auto;
										border-radius: 1.5px;
										background-color: #d2d5d9;
									}
								}
							}

							&.type_detail {
								&:not(:first-child){
									margin-top: 10px;
								}

								.main_list_item {
									line-height: 22px;
									vertical-align: top;
									letter-spacing: -0.5px;
									font-size: 15px;
									font-weight: 600;
									color: #555;

									&:not(:first-child){
										margin-top: 5px;
									}
								}
								.main_item_link {
									&.type_promotion {
										display: inline-block;
										line-height: inherit;
										vertical-align: top;
										letter-spacing: -0.5px;
										word-break: keep-all;
										font-size: inherit;

										&:hover {
											color: #2e73f2;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}