
          @import '@UI/scss/global.scss';
        
.header {
	&.type_main {
		z-index: 10;
		box-shadow: 0px 2px 7px 0 rgb(0, 0, 0, 0.1);
		background-color: #fff;

		&:not(.is-fixed){
			position: relative;
		}

		&.is-fixed {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			// transition: top 0.25s ease-out;

			& ~ section {
				padding-top: 56px;
			}
		}
	}

	.header_inner {
		max-width: 1024px;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		padding: 10px 20px;
		flex-direction: row;
	}

	.header_title {
		min-width: 0;
		max-width: 100%;
		align-self: flex-start;

		.header_title_link {
			display: block;

			&.type_basic {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin: -10px;
				padding: 10px;
				font-size: 24px;
				font-weight: bold;

				&:hover {
					color: #2e73f2;
				}
			}
		}
	}

	.gnb {
		padding-top: 6px;
		padding-bottom: 6px;
		text-align: right;

		&:not(:first-child){
			max-width: 80%;
			padding-left: 20px;
			flex: 1 0 auto;
		}

		.gnb_item {
			margin: -10px;
			padding: 10px;
			line-height: 24px;
			letter-spacing: -0.5px;
			font-size: 18px;
			font-weight: bold;

			&:not(:first-child){
				margin-left: 10px;
			}

			&.is-active {
				position: relative;
				color: #2e73f2;

				&::after {
					content: "";
					height: 2px;
					position: absolute;
					bottom: 0;
					left: 5px;
					right: 5px;
					background-color: #2e73f2;
				}
			}
		}
	}
}