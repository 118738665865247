
          @import '@UI/scss/global.scss';
        
.main_profile {
	flex: 1 0 auto;

	&:not(:first-child){
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid rgba(#000 ,0.1);
	}

	.main_profile_inner {
		max-width: 1024px;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px;
		flex-direction: column;
	}
}

.main_profile_title {
	line-height: 39px;
	letter-spacing: -0.3px;
	font-size: 26px;
	font-weight: bold;
	color: #151515;

	&:not(:first-child){
		margin-top: 40px;
	}
}

.main_profile_introduce {
	display: flex;
	flex-direction: row;

	&:not(:first-child){
		margin-top: 30px;
	}

	.main_introduce_list {
		&.type_basic {
			display: block;

			& > .main_list_item {
				&:not(:first-child){
					margin-top: 20px;
				}
			}

			.main_description_title {
				line-height: 22px;
				letter-spacing: -0.7px;
				font-size: 15px;
				color: #777;
			}

			.main_description_data {
				line-height: 22px;
				letter-spacing: -0.5px;
				font-size: 15px;
				color: #222;

				&:not(:first-child){
					margin-top: 10px;
				}

				.main_data_list {
					&.type_basic {
						.main_list_item {
							display: flex;
							flex-direction: row;

							&:not(:first-child){
								margin-top: 5px;
							}

							&:before {
								content: "";
								width: 3px;
								height: 3px;
								display: inline-block;
								margin-top: 8px;
								margin-left: 6px;
								margin-right: 6px;
								vertical-align: top;
								flex: 0 0 auto;
								border-radius: 1.5px;
								background-color: #d2d5d9;
							}
						}

						.main_item_link {
							&.type_contact {
								display: inline-block;
								vertical-align: top;
								word-break: break-all;

								&:hover {
									color: #2e73f2;
								}	
							}
						}
					}
				}
			}
		}
	}
}