@function rgbaToRgb($rgba, $background: #fff) {
    @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%);
}

@mixin ellipsis ($lines: 1, $line-height: 16px) {
    overflow: hidden;
    text-overflow: ellipsis;

    @if $lines == 1 {
        white-space: nowrap;
    } @else {
        $height: $line-height * $lines;

        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
        line-height: $line-height;
        height: $height;
        word-break: break-all;
        word-wrap: break-word;
    }
}

/// background gradient
/// @param {color} $start-color - 그라데이션 시작 컬러
/// @param {color} $end-color - 그라데이션 끝 컬러
/// @param {string} $orientation [vertical] - 그라데이션의 방향 horizontal/radial
@mixin backgroundGradient($start-color, $end-color, $orientation: vertical) {
    $iecolor-start: ie-hex-str($start-color);//ie filter에서 사용하는 시작 색상 변환
    $iecolor-end: ie-hex-str($end-color);//ie filter에서 사용하는 끝 색상 변환

    @if $orientation == 'horizontal' {
        background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );

    } @else if $orientation == 'radial' {
        background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
    } @else {
        background: -moz-linear-gradient($start-color 0%, $end-color 100%);
        background: -webkit-linear-gradient($start-color 0%, $end-color 100%);
        background: linear-gradient($start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$iecolor-start}', endColorstr='#{$iecolor-end}',GradientType=0 );
    }
 }

 @mixin blind {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin childLength($selector: &, $max : 5, $calc: false, $margin: 0){
    @if ($calc == true){
        &:first-child:nth-last-child(1) {
            width: clac(100%);
        }

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ .#{$selector} {
            width: calc(50% - #{$margin});
        }

        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3) ~ .#{$selector} {
            width: calc(33.3333% - #{$margin});
        }

        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ .#{$selector} {
            width: calc(25% - #{$margin});
        }

        &:first-child:nth-last-child(5),
        &:first-child:nth-last-child(5) ~ .#{$selector} {
            width: calc(20% - #{$margin});
        }
    }@else{
        &:first-child:nth-last-child(1) {
            width: 100%;
        }

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ .#{$selector} {
            width: 50%;
        }

        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3) ~ .#{$selector} {
            width: 33.3333%
        }

        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ .#{$selector} {
            width: 25%;
        }

        &:first-child:nth-last-child(5),
        &:first-child:nth-last-child(5) ~ .#{$selector} {
            width: 20%;
        }
    }
}

@mixin append-attr($x) {
    $sel: &;
    $collector: ();

    @for $i from 1 through length($sel) {
        $s: nth($sel, $i);
        $last: nth($s, -1);
        @if str-slice($last, -1) == "]" {
            // if is just the bare attribute with no value, $offset will be -1, otherwise it will be -2
            $offset: -1;
            $current-x: $x;

            @if str-slice($last, -2) == '"]' {
                // this attribute already has a value, so we need to adjust the offset
                $offset: -2;
            } @else {
                // no attribute value, so add the equals and quotes
                $current-x: '="' + $x + '"';
            }
            $last: str-slice($last, 1, $offset - 1) + $current-x + str-slice($last, $offset);
            $collector: append($collector, set-nth($s, -1, $last), comma);
        } @else {
            // following line will append $x to your non-attribute selector
            $collector: append($collector, selector-append($s, $x), comma);
            // the following line will not change your non-attribute selector at all
            //$collector: append($collector, $s, comma);
        }
    }

    @at-root #{$collector} {
        @content;
    }
}