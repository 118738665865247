
          @import '@UI/scss/global.scss';
        
@charset "UTF-8";

.content {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	// background-image: url(/images/CONY.png); 절대 경로로 적용

	&.type_main {
		.content_inner {
			&:first-child:last-child {
				padding-top: 30px;
			}
		}
	}

	.content_inner {
		display: flex;
		flex: 1 0 auto;
		flex-direction: column;
	}
}