@charset "UTF-8";

body, p,
h1, h2, h3, h4, h5, h6,
ul, ol, li,
dl, dt, dd,
table, th, td,
form, fieldset, legend, input, textarea, button, select {
    margin: 0;
    padding: 0;
}

body, p,
h1, h2, h3, h4, h5, h6,
ul, ol, li,
dl, dt, dd,
table, th, td,
form, fieldset, legend, input, textarea, button, select {
    margin: 0;
    padding: 0;
}

body,
input,
textarea,
select,
button,
table {
    font-size: 14px;
    line-height: 1.5;
}

table {
    width: 100%;
}

body {
    position: relative;
    -webkit-text-size-adjust: none;
}
body {
    width: 100%;
    font-family: -apple-system, "Helvetica Neue", "Apple SD Gothic Neo", Malgun Gothic, "돋움", Dotum, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    color: #222;
    background-color: #fff;
}

img {
    max-width: 100%;
    vertical-align: top;
}

img,
fieldset {
    border: 0;
}

ul,
ol {
    list-style: none;
}

em,
address {
    font-style: normal;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

table {
    border-collapse: collapse;
}

i {
    font-style: normal;
}

a, abbr, address, article, aside, audio, b, blockquote, body, br, button, canvas, caption, cite, code, col, data, datalist, dd, del, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hr, html, i, iframe, img, input, ins, kbd, label, legend, li, main, mark, meter, nav, object, ol, output, p, pre, progress, q, s, samp, section, select, small, span, strong, sub, sup, table, td, textarea, th, time, u, ul, var, video {
    box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
    display: block;
}

strong {
    font-weight: 400;
}

pre {
    margin: 0;
    padding: 0;
    line-height: inherit;
    word-break: break-all;
    white-space: pre-line;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

code {
    font-family: inherit;
}
