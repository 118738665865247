
          @import '@UI/scss/global.scss';
        
.exception {
	&.type_default {
		margin: auto;
		padding: 20px;
		text-align: center;

		.exception_title {
			color: #151515;
			font-size: 26px;
			font-weight: 700;
			letter-spacing: -.3px;
			line-height: 40px;
			word-break: keep-all;

			&:not(:first-child){
				margin-top: 30px;
			}
		}
	}
}

.exception_link {
	&.type_back {
		display: inline-block;
		line-height: 24px;
		letter-spacing: -0.5px;
		vertical-align: top;
		font-size: 16px;
		color: #666;

		&:not(:first-child){
			margin-top: 20px;
		}
	}
}