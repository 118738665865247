
          @import '@UI/scss/global.scss';
        
.footer {
	text-align: center;
	border-top: 1px solid rgba(#000 ,0.1);

	&:not(:first-child){
		margin-top: 30px;
	}

	.footer_inner {
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
		padding: 10px;
	}

	.footer_text {
		&.type_basic {
			color: #333;
		}
	}
}