
          @import '@UI/scss/global.scss';
        
.main_tech {
	flex: 1 0 auto;

	&:not(:first-child){
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid rgba(#000 ,0.1);
	}

	.main_tech_inner {
		max-width: 1024px;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px;
		flex-direction: column;
	}
}

.main_tech_title {
	line-height: 39px;
	letter-spacing: -0.3px;
	font-size: 26px;
	font-weight: bold;
	color: #151515;
}

.main_tech_introduce {
	&:not(:first-child){
		margin-top: 30px;
	}

	.main_introduce_skills {
		&:not(:first-child){
			margin-top: 30px;
			padding-top: 30px;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}

		.main_skills_title {
			line-height: 24px;
			letter-spacing: -0.5px;
			font-size: 18px;
			font-weight: 600;
			color: #333;
		}

		.main_skills_about {
			&:not(:first-child){
				margin-top: 30px;
			}

			.main_about_description {
				&:not(:first-child){
					margin-top: 20px;
				}

				.main_description_title {
					line-height: 24px;
					letter-spacing: -0.7px;
					font-size: 16px;
					color: #777;
				}

				.main_description_data {
					line-height: 22px;
					letter-spacing: -0.5px;
					font-size: 15px;
					color: #222;

					&:not(:first-child){
						margin-top: 15px;
					}
				}

				.main_data_title {
					display: block;
					line-height: 22px;
					letter-spacing: -0.7px;
					font-size: 15px;
					font-weight: 700;
					color: #333;
				}

				.main_data_list {
					&.type_dot {
						.main_list_item {
							position: relative;
							padding-left: 15px;

							&:before {
								content: "";
								width: 3px;
								height: 3px;
								position: absolute;
								top: 8px;
								left: 6px;
								border-radius: 1.5px;
								background-color: #d2d5d9;
							}
						}
					}

					&:not(:first-child){
						margin-top: 10px;
					}

					.main_list_item {
						line-height: 22px;
						letter-spacing: -0.5px;
						word-break: keep-all;
						font-size: 15px;
						color: #222;

						&:not(:first-child){
							margin-top: 5px;
						}
					}

					.main_item_link {
						&.type_basic {
							display: inline-block;
							vertical-align: top;

							&:not(:last-child){
								margin-right: 4px;
							}
						}

						&.type_emphasis {
							font-weight: 600;
							color: #2e73f2;
						}
					}
				}
			}
		}
	}
}