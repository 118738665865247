@charset "UTF-8";

html,
body {
    min-width: 320px;
    height: 100%;
}

:global(#root) {
    height: 100%;
    display: flex;
    flex-direction: column;
}

:global(#wrap) {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    & > :last-child {
        padding-bottom: calc(constant(safe-area-inset-bottom));
        padding-bottom: calc(env(safe-area-inset-bottom));
    }
}

:global(.u_skip) {
    a{position:fixed;left:0;top:-45px;width:100%;z-index:999;padding:5px;background-color:#000;color:#fff}
    a:focus{width:auto;top:0;text-decoration:none}
}

h1,h2,h3,h4,h5,h6,:global(.heading){
    letter-spacing:-1px;
    font-size: inherit;
    font-weight: normal;
}

%blind{
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
}

:global(.skip_navigation) {
    position: relative;
}

:global(.skip_navigation) a {
    position: fixed;
    left: 0;
    top: -45px;
    width: 100%;
    z-index: 999;
    padding: 5px;
    background-color: #000;
    color: #fff;
    &:focus{
        width: auto;
        top: 0;
        text-decoration: none;
    }
}

/* 숨김처리 */
:global(.blind){
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    margin: -1px;
    clip: rect(0 0 0 0);
}

caption {
     font-size:0;
     color:transparent;
}

/* input */
input,textarea,select,button{
    vertical-align: top;
    font-family: -apple-system,"Helvetica Neue","Apple SD Gothic Neo",sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border: none;
    box-shadow:none;
    border-radius:0;
    background-color: transparent;
}

input {
    @include placeholder {
        color: #919191;
        opacity: 1;
    }
}

:global(button) {
    touch-action: manipulation;
}

button[disabled] {
   border: none;
}

select::-ms-expand {
    display: none;
}

input::-ms-clear {
    display: none;
}

input {
    &[type="checkbox"], &[type="radio"] {
        @extend %blind;
        + label {
            cursor: pointer;
        }
        &:disabled + label {
            cursor: default;
        }
    }
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
    opacity:0;
    -webkit-appearance: none;
 }

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  color:transparent;
}

input:focus+label {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

label {
    display: inline-block;
}

textarea {
    resize: none;
}

input, select, textarea {
    width: 100%;
}

svg {
    display: inline-block;
    vertical-align: top;
}

div[aria-hidden='false']{
    display: block;
}

div[aria-hidden='true']:not(:global(.wrap)) {
    display: none;
}

:global(.dimmed){
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

:global(.dimmed)[aria-hidden="false"] {
    display: block;
}

:global(.dimmed)[aria-hidden="true"] {
    display: none;
}

:global(.is-popup) {
    overflow: hidden;
}

:global(header) {
    z-index: 2001;
}